import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { DedicationFragment } from '../../entities/operationResults';
import Title from '../atoms/Title';
import BodyText from '../atoms/BodyText';
import ContentSection from '../molecules/ContentSection';

export const dedicationFragment = graphql`
    fragment DedicationFragment on Craft_DedicationSuperTableBlockType {
        image {
            ...CommonImageFragment
        }
        dedicationTitle
        description
        statement
    }
`;

interface Props {
    data: DedicationFragment;
}

const Dedication: FC<Props> = ({ data: { image, dedicationTitle, description, statement } }) => {
    if (dedicationTitle && image && image[0]) {
        return (
            <ContentSection leftMargin={4} alignImage={'bottom'} image={image[0]}>
                <Title variant="medium">{dedicationTitle}</Title>
                <BodyText>{description}</BodyText>
                <p>
                    <strong>{statement}</strong>
                </p>
            </ContentSection>
        );
    }
    return null;
};

export default Dedication;
