import { graphql } from 'gatsby';
import React, { FC, useContext } from 'react';
import { SupportiveFragment } from '../../entities/operationResults';
import ContentLink from '../atoms/ContentLink';
import Title from '../atoms/Title';
import ContentSection from '../molecules/ContentSection';
import CmsContent from '../atoms/CmsContent';
import { SiteContext } from '../../contexts/SiteContext';

export const supportiveFragment = graphql`
    fragment SupportiveFragment on Craft_SupportiveSuperTableBlockType {
        image {
            ...CommonImageFragment
        }
        supportiveTitle
        description {
            content
        }
        supportLink {
            entry {
                uri
            }
            customText
        }
    }
`;

interface Props {
    data: SupportiveFragment;
}

const Supportive: FC<Props> = ({ data: { description, image, supportiveTitle, supportLink } }) => {
    const site = useContext(SiteContext);
    if (description && image && image[0]) {
        return (
            <ContentSection leftMargin={4} alignImage={'top'} image={image[0]}>
                <Title variant="medium">{supportiveTitle}</Title>
                {description && description.content && <CmsContent html={description.content} />}
                {supportLink && supportLink.entry && supportLink.entry.uri && supportLink.customText && (
                    <ContentLink to={site ? `/${site}/${supportLink.entry.uri}/` : `/${supportLink.entry.uri}/`}>{supportLink.customText}</ContentLink>
                )}
            </ContentSection>
        );
    }
    return null;
};

export default Supportive;
