import { graphql } from 'gatsby';
import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import { ClientsShowcaseFragment } from '../../entities/operationResults';
import Grid from '../atoms/Grid';
import Title from '../atoms/Title';

export const clientsShowcaseFragment = graphql`
    fragment ClientsShowcaseFragment on Craft_ClientsSuperTableBlockType {
        id
        description
        logo {
            url
        }
    }
`;

interface Props {
    data: Array<ClientsShowcaseFragment | null>;
    title: string | null;
}

const ClientsShowcase: FC<Props> = ({ data, title }) => {
    return (
        <Fragment>
            {title && (
                <Grid marginRight={2}>
                    <TitleCell length={data ? data.length : 0}>
                        <Title variant="large">{title}</Title>
                    </TitleCell>
                </Grid>
            )}
            {data && (
                <ClientSection length={data.length}>
                    {data.map((client, i) => {
                        if (client && client.logo && client.description) {
                            const { id, logo, description } = client;
                            return (
                                <Client key={id || i}>
                                    {logo?.[0]?.url && <Logo alt={description} src={logo?.[0]?.url} />}
                                    <Description>{description}</Description>
                                </Client>
                            );
                        }
                        return null;
                    })}
                </ClientSection>
            )}
        </Fragment>
    );
};

export default ClientsShowcase;

const Logo = styled.img`
    margin: auto;
    max-width: 25rem;
    filter: brightness(0);
`;

const TitleCell = styled.div<{ length: number }>`
    grid-column: 3 / 10;
    ${({ length }) => (length > 1 ? `grid-row: 4 / 16;` : `grid-row: 1 / -1;`)}
    align-self: center;

    @media screen and (min-height: ${theme.mediaQueries.height.m}) and (min-width: ${theme.mediaQueries.width.m}) {
        grid-column: 4 / 10;
    }

    @media screen and (min-height: ${theme.mediaQueries.height.m}) and (min-width: ${theme.mediaQueries.width.xl}) {
        grid-column: 4 / 9;
    }
`;
const Description = styled.div`
    margin: 0 auto;
    text-align: center;
    max-width: 25rem;
    display: none;
    @media screen and (min-height: ${theme.mediaQueries.height.s}) and (min-width: ${theme.mediaQueries.width.m}) {
        display: unset;
    }
`;

const ClientSection = styled.div<{ length: number }>`
    display: grid;
    ${({ length }) => length > 1 && `grid-template-rows: repeat(2, fit-content(30vh));`}
    grid-auto-flow: column;
    grid-auto-columns: 40rem;
    row-gap: 10vh;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10rem 20rem;

    @media (${theme.mediaQueries.vertical}) {
        display: flex;
        flex-direction: column;
        padding: 5rem 0;
    }
`;

const Client = styled.div`
    display: flex;
    flex-direction: column;
    @media (${theme.mediaQueries.vertical}) {
        height: 15vh;
        min-height: 10rem;
    }

    @media (${theme.mediaQueries.horizontal}) {
        box-sizing: border-box;
        min-width: 40rem;
        height: 30vh;
        border-right: 1px solid rgba(0, 0, 0, 0.25);
        :first-child {
            border-left: 1px solid rgba(0, 0, 0, 0.25);
        }
        :nth-child(2) {
            border-left: 1px solid rgba(0, 0, 0, 0.25);
        }
    }
`;
