import { graphql } from 'gatsby';
import React, { FC, Fragment } from 'react';
import theme from '../constants/theme';
import { AboutUsPageFragment, AboutUsQuery, CTAFragment } from '../entities/operationResults';
import withDefaultTransition from '../hocs/withDefaultTransition';
import { Section } from './atoms/Section';
import CTA from './molecules/CTA';
import ClientsShowcase from './organisms/ClientsShowcase';
import Dedication from './organisms/Dedication';
import Footer from './organisms/Footer';
import Hero from './organisms/Hero';
import Intro from './organisms/Intro';
import Supportive from './organisms/Supportive';
import styled from 'styled-components';
import Meta from './atoms/Meta';
import { removeTags } from '../utils/removeTags';

interface Props {
    data: AboutUsQuery;
}

export const query = graphql`
    query AboutUsQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...AboutUsPageFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment AboutUsPageFragment on Craft_AboutUs {
        id
        title
        hero {
            ...HeroFragment
        }
        intro {
            ...IntroFragment
        }

        supportive {
            ...SupportiveFragment
        }

        dedication {
            ...DedicationFragment
        }
        callToAction {
            ...CTAFragment
        }
        clientsTitle
        clients {
            ...ClientsShowcaseFragment
        }
        directContact {
            ...DirectContactFragment
        }
        seo {
            ...MetaFragment
        }
    }
`;

const AboutUsPage: FC<Props> = ({ data }) => {
    const entry = data.craft.entry as AboutUsPageFragment;
    const ctaList = (entry?.callToAction || []) as CTAFragment[];

    return entry ? (
        <Fragment>
            <Meta
                title={entry.seo?.[0]?.seoTitle ?? entry.title}
                description={entry.seo?.[0]?.seoDescription ?? removeTags(entry.intro?.[0]?.text?.content ?? null)}
                image={entry.seo?.[0]?.seoImage ?? null}
            />
            <Section>
                {entry.hero?.[0] && <Hero data={entry.hero[0]} ratio={1369 / 2237} />}
                {entry.intro?.[0] && <Intro data={entry.intro[0]} variant="aboutUs" />}
            </Section>
            {entry.dedication?.[0] && (
                <Section>
                    <Dedication data={entry.dedication[0]} />
                </Section>
            )}
            {entry.supportive?.[0] && (
                <Section>
                    <Supportive data={entry.supportive[0]} />
                </Section>
            )}
            {entry.clients && (
                <ClientsShowcaseSection>
                    <Section background={theme.colors.gorse} as={'div'}>
                        <ClientsShowcase title={entry.clientsTitle} data={entry.clients} />
                        {ctaList[0] && <CTA sourceId={entry.id} data={ctaList[0]} />}
                    </Section>
                </ClientsShowcaseSection>
            )}
            <Section variant={'footer'}>
                {entry.directContact?.[0] && <Footer entryId={entry.id} data={entry.directContact?.[0]} hasFlatTop />}
            </Section>
        </Fragment>
    ) : null;
};

const ClientsShowcaseSection = styled.section`
    z-index: 1;

    > section {
        height: 100%;
    }

    @media screen and (${theme.mediaQueries.vertical}) {
        margin-bottom: -10rem;
        margin-top: -5rem;
    }
`;

export default withDefaultTransition(AboutUsPage);
